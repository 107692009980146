import UnitTile, { Price, Text } from '@oberoninternal/travelbase-ds/components/misc/UnitTile';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { useRandom } from '@oberoninternal/travelbase-ds/hooks/useRandom';
import Package from '@oberoninternal/travelbase-ds/svg/Package.svg';
import Special from '@oberoninternal/travelbase-ds/svg/Special.svg';
import { Box, Flex } from '@rebass/grid';
import { gql } from '@apollo/client';
import Link from 'next/link';
import React, { HTMLAttributes, memo, useMemo } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { useTenantContext } from '../context/TenantContext';
import { SearchHitRentalUnitFragment, SearchHitTripFragment, Trip, TripTypeEnum } from '../generated/graphql';
import createPlaceholder from '../utils/createPlaceholder';
import createUnitTitle from '../utils/createUnitTitle';
import getRentalUnitSummaryText from '../utils/getRentalUnitSummaryText';
import { getDateStringsFromTrip } from '../utils/trip';
import LinkWrapper from './LinkWrapper';
import Rating from './Rating';
import Stack from './Stack';
import { getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';
import EmptyStar from './svg/EmptyStar.svg';
import getPercentage from '../utils/getPercentage';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { parse, stringify } from 'query-string';
import FavoriteIconButton from './FavoriteIconButton';
import useGetPageSlugByLocale from '../hooks/useGetPageSlugByLocale';

export const fragment = gql`
    fragment SearchHitRentalUnit on RentalUnit {
        id
        name
        slug
        type
        code
        brand
        amountBedrooms
        maxOccupancy
        flexCancellationDays
        hasBadge: hasFilterProperty(handle: $badgeHandle)
        hasbadge2: hasFilterProperty(handle: $badge2Handle)
        reviewStats {
            count
            average
        }
        mainImage {
            transform(config: HERO, format: WEBP) {
                src
            }
        }
        webpListImage: listImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        listImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
        accommodation {
            id
            place
            address {
                city
            }
            name
            hasPublicPage
            collapseInSearch
            coordinates {
                lat
                lon
            }
        }
        tagline
        fullCircleImageUrl
        videoUrl
    }

    fragment SearchHitTrip on Trip {
        originalPrice
        date
        duration
        price
        type
        special {
            id
            name
            description
            landingPageUrl
        }
    }
    fragment SearchHit on UnitSearchHit {
        rentalUnit {
            ...SearchHitRentalUnit
        }
        trips {
            ...SearchHitTrip
        }
    }

    fragment ListImage on ImageTransform {
        placeholder
        srcSet
        src
        ratio
    }

    fragment Extras on AttributeCategory {
        name
        handle
        attributes {
            id
            name
            iconUrl
            handle
        }
    }
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
    rentalUnit: SearchHitRentalUnitFragment;
    trips: SearchHitTripFragment[];
    activeSpecial?: string;
    showPrice?: boolean;
    loading?: false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unitParams: Record<string, any>;
    /* display responsive images with matching intrinsic widths */
    sizes?: string;
    onHitClick?: (unit: SearchHitRentalUnitFragment, searchParams: string) => void;
}

// When we're loading, the data prop isn't required
interface PartialProps extends Partial<Omit<Props, 'loading'>> {
    loading: true;
}

const SearchHit = (props: Props | PartialProps) => {
    const { getPageSlugByLocale } = useGetPageSlugByLocale();
    const accommodationPageSlug = getPageSlugByLocale('ACCOMMODATION');
    const intl = useIntl();
    const { brandConfig } = useTenantContext();

    const didFilterByBrand = useMemo(() => {
        const searched = parse(props.unitParams?.searched ?? '');
        if (Array.isArray(searched?.orFilters)) {
            return searched.orFilters.includes('_brand');
        }
        return searched.orFilters?.startsWith('_brand');
    }, [props.unitParams?.searched]);

    if (props.loading) {
        return <SkeletonSearchHit />;
    }

    const {
        rentalUnit,
        activeSpecial,
        showPrice = true,
        loading,
        unitParams: { arrivalDate, departureDate, ...unitParams },
        sizes,
        ...rest
    } = props;

    const {
        id,
        mainImage,
        slug,
        reviewStats,
        maxOccupancy,
        amountBedrooms,
        type,
        name,
        flexCancellationDays,
        tagline,
        brand,
        listImage: { transform: photo },
        webpListImage: { transform: webpPhoto },
        accommodation: {
            hasPublicPage,
            name: accommodationName,
            place,
            address: { city },
            collapseInSearch,
        },
        videoUrl,
        fullCircleImageUrl,
    } = rentalUnit;
    // data.trips is readonly
    const trips = [...props.trips].sort((a, b) => a.price - b.price);

    const unitType = unitParams.accommodationType === 'all' ? type : undefined;

    const subTitleText = getRentalUnitSummaryText(intl, brandConfig)(
        city ?? place,
        unitType,
        maxOccupancy,
        amountBedrooms,
        !didFilterByBrand ? brand : undefined
    );

    // index lookup type should include undefined 🤨
    const bestTrip = trips[0] as Trip | undefined;
    const foundTrip = activeSpecial ? trips.find(trip => trip.special?.id === activeSpecial) ?? bestTrip : bestTrip;

    const dimHit = !foundTrip && arrivalDate && departureDate;

    let leftIcon;
    if (rentalUnit.hasBadge || rentalUnit.hasbadge2) {
        leftIcon = [];
        if (rentalUnit.hasBadge && brandConfig.badge?.icon) {
            leftIcon.push(brandConfig.badge.icon);
        }
        if (rentalUnit.hasbadge2 && brandConfig.badge2?.icon) {
            leftIcon.push(brandConfig.badge2.icon);
        }
        if (leftIcon.length === 0) {
            leftIcon = undefined;
        }
    }
    const query = {
        ...unitParams,
        ...(foundTrip && getDateStringsFromTrip(foundTrip)),
        ...(activeSpecial && { specialId: activeSpecial }),
        withHero: +!!mainImage?.transform?.src,
    };

    return (
        <Link
            href={{
                query,
                pathname: `/${accommodationPageSlug}/${slug}`,
            }}
            passHref
            legacyBehavior
        >
            <LinkWrapper
                data-cy={name}
                onClick={e => {
                    if (props.onHitClick) {
                        e.preventDefault();
                        props.onHitClick(rentalUnit, stringify(query));
                    }
                }}
            >
                <StyledUnitTile
                    photo={{
                        sources: [
                            {
                                srcSet: webpPhoto?.srcSet,
                                type: 'image/webp',
                                sizes,
                            },
                            {
                                srcSet: photo?.srcSet,
                                type: 'image/jpeg',
                                sizes,
                            },
                        ],
                        fallback: {
                            src: photo?.src,
                        },
                        loading: 'lazy',
                        placeholder: createPlaceholder(webpPhoto?.placeholder),
                    }}
                    {...rest}
                    style={{ ...rest.style, opacity: dimHit ? 0.5 : 1 }}
                    discountName={
                        foundTrip?.type === TripTypeEnum.LastMinute ? (
                            <FormattedMessage defaultMessage="Lastminute aanbieding" />
                        ) : (
                            foundTrip?.special?.name
                        )
                    }
                    discountDescription={foundTrip?.special?.description}
                    discountIcon={foundTrip?.originalPrice ? <Special /> : <Package />}
                    leftIcon={leftIcon}
                    rightIcon={
                        brandConfig.enableFavorites && (
                            <FavoriteIconButton
                                type="rentalUnit"
                                id={id}
                                landingUrl={
                                    typeof window !== 'undefined'
                                        ? `${window.location.origin}/${intl.locale}/my`
                                        : undefined
                                }
                            />
                        )
                    }
                    videoUrl={videoUrl}
                    fullCircleImageUrl={fullCircleImageUrl}
                >
                    <HitMeta>
                        <div>
                            {reviewStats?.count ? (
                                <Flex my={2}>{reviewStats?.average && <Rating value={reviewStats.average} />}</Flex>
                            ) : (
                                <Flex my={2}>
                                    <Container>
                                        <EmptyStar />
                                        <NoReviews variant="small">
                                            <FormattedMessage defaultMessage="Geen beoordelingen" />
                                        </NoReviews>
                                    </Container>
                                </Flex>
                            )}
                            <HitMetaTitle variant="small">
                                {collapseInSearch ? createUnitTitle(name, accommodationName, hasPublicPage) : tagline}
                            </HitMetaTitle>
                        </div>
                        <div>
                            <StyledTitle>
                                {collapseInSearch ? tagline : createUnitTitle(name, accommodationName, hasPublicPage)}
                            </StyledTitle>
                            <StyledText variant="small" style={{ marginTop: '0.2rem', fontWeight: 400 }}>
                                {subTitleText}
                            </StyledText>
                            {showPrice && foundTrip && (
                                <StyledPrice>
                                    <Price
                                        originalPrice={
                                            foundTrip.originalPrice && (
                                                <FormattedNumber format="EUR" value={foundTrip.originalPrice ?? 0} />
                                            )
                                        }
                                        totalPrice={<FormattedNumber format="EUR" value={foundTrip.price} />}
                                        totalNights={
                                            <>
                                                <CircleBullet>&#8226;</CircleBullet>
                                                <FormattedMessage
                                                    defaultMessage="{duration, number} {duration, plural, one {nacht} other {nachten}}"
                                                    values={{
                                                        duration: foundTrip.duration,
                                                    }}
                                                />
                                            </>
                                        }
                                        discountPercentage={getPercentage(
                                            foundTrip.price,
                                            foundTrip.originalPrice ?? 0
                                        )}
                                    />
                                </StyledPrice>
                            )}
                            {flexCancellationDays ? (
                                <CancellationDay>
                                    <FormattedMessage
                                        defaultMessage="Gratis annuleren tot {flexCancellationDays} dagen"
                                        values={{
                                            flexCancellationDays,
                                        }}
                                    />
                                </CancellationDay>
                            ) : (
                                <CancellationDay>&nbsp;</CancellationDay>
                            )}
                            {dimHit && (
                                <Text variant="large" style={{ fontWeight: 500 }}>
                                    <FormattedMessage defaultMessage="Niet beschikbaar" />
                                </Text>
                            )}
                        </div>
                    </HitMeta>
                </StyledUnitTile>
            </LinkWrapper>
        </Link>
    );
};

export default memo(SearchHit);

export const HitMeta = styled.div`
    // some weird :nth-of-type selector is setting display: flex here...
    display: flex;
    flex-direction: column;
    align-items: start !important;
    height: 100%;
    flex: 1;
    flex-flow: column;
    justify-content: space-between;
`;

const StyledUnitTile = styled(UnitTile)`
    width: unset;
    opacity: 1;
    height: calc(100% - 5rem);
    flex: 1;
    display: flex;
    flex-flow: column;
    && {
        :last-of-type {
            margin-right: 0;
        }
        margin-right: 0;
    }
`;
const SkeletonSearchHit = () => (
    <Box className="tile-m">
        <Box
            style={{
                width: '100%',
                paddingBottom: 'calc(3 / 4 * 100%)',
                position: 'relative',
            }}
        >
            <Box style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}>
                <Skeleton width="100%" height="100%" />
            </Box>
        </Box>
        <Stack spacing={3} marginTop={4} style={{ overflow: 'hidden' }}>
            {new Array(3).fill(null).map((_, i) => (
                <SkeletonField key={i} />
            ))}
        </Stack>
    </Box>
);

const SkeletonField = () => {
    const random = useRandom(60, 350);
    return <Skeleton height="30" width={random} />;
};
const StyledTitle = styled.p`
    color: ${({ theme }) => theme.colors.neutral['60']};
    font-size: 1.6rem;
    margin-bottom: ${({ theme }) => theme.spacing['10_Micro']};
    margin-top: 0.8rem;
    font-weight: 400;
`;
const StyledPrice = styled.div`
    > p {
        line-height: 1.5;
        font-weight: 700;
    }
    * > span {
        font-weight: 400;
        color: ${({ theme }) => theme.colors.neutral['60']};
    }
`;

const CircleBullet = styled.span`
    margin-right: 0.8rem;
`;

const NoReviews = styled(Body)`
    font-weight: 700;
    color: #6f6f6f;
    margin-left: 0.4rem;
`;

const HitMetaTitle = styled(Title)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) =>
        theme.colorAssignments.rating
            ? getAssignmentColor(theme, theme.colorAssignments.rating)
            : theme.colors.secondary['30']};
    > p {
        margin-left: ${({ theme }) => theme.spacing['20_Tiny']};
        font-weight: 700;
    }
    > svg {
        margin-top: -${({ theme }) => theme.spacing['10_Micro']};
        width: 1.6rem;
    }
`;

const CancellationDay = styled(Body)`
    color: ${({ theme }) => theme.colors.secondary['30']};
    font-size: 1.5rem;
    font-weight: 700;

    @media screen and (min-width: 1440px) {
        font-size: 1.5rem;
    }

    @media screen and (max-width: 1440px) {
        font-size: 1.4rem;
    }

    @media screen and (max-width: 1035px) {
        font-size: 1.3rem;
    }

    @media screen and (max-width: 1020px) {
        font-size: 1.4rem;
    }

    @media screen and (max-width: 720px) {
        font-size: 1.3rem;
    }

    @media screen and (max-width: 650px) {
        font-size: 1.4rem;
    }

    @media screen and (max-width: 353px) {
        font-size: 1.2rem;
    }
`;

const StyledText = styled(Text)`
    color: ${({ theme }) => theme.colors.neutral['60']};
    font-size: 1.6rem;
`;
